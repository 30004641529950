// ======================================================================
// IMPORTS
// ======================================================================

import { init } from '@rematch/core';
import { appModel } from './models.app';

// ======================================================================
// STORE
// ======================================================================

const store = init({
  models: {
    appModel,
  },
  // plugins: [selectPlugin()],
});

// ======================================================================
// GLOBAL ACCESS
// ======================================================================

const isLocal = process.env.NODE_ENV === 'development';

if (isLocal) {
  // setTimeout(function () {
  //   if (window) {
  //     window.store = store;
  //   }
  // }, 500);
  // store.getState().appModel.xxxxxx
  // store.dispatch.appModel.xxxxxx
}

// ======================================================================
// EXPORT
// ======================================================================

export default store;
