// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

export default function NoPrizes() {
  const content = useSelector((models) => models.appModel.content?.errors?.prizes);

  // const isLocal = process.env.NODE_ENV === 'development';
  // const navigate = useNavigate();

  // // init
  // useEffect(() => {
  //   // local dev - populate placeholder game id
  //   if (isLocal) {
  //     const gameId = 'rsa_spin2win_game_test_1';
  //     navigate('/' + gameId);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return <main>{content ? content : 'there are no more\nprizes available'}</main>;
}
