import { useSelector } from 'react-redux';

import style from './TextUnderage.module.scss';

export const TextUnderage = ({ children }) => {
  const content = useSelector((models) => models.appModel.content.pages.underage);

  return (
    <div className={style.wrap}>
      <p>{content.body}</p>
    </div>
  );
};

export default TextUnderage;
