import clsx from 'clsx';
import { useSelector } from 'react-redux';

import style from './Legal.module.scss';

export const Legal = () => {
  const content = useSelector((models) => models.appModel.content.legal);

  return (
    <div className={style.wrap}>
      {content.terms && (
        <div className={clsx(style.terms, style[content.terms.position])}>
          <a href={content.terms.link} target="_blank" rel="noreferrer noopener">
            {content.terms.text}
          </a>
        </div>
      )}
      <div className={clsx(style.icon, style[content.icon.position])}>
        <img src={content.icon.image} alt="Age limit icon" />
      </div>
    </div>
  );
};

export default Legal;
