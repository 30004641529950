import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Brand } from 'js/components';

export default function Page() {
  const navigate = useNavigate();
  const [animationState, setAnimationState] = useState('visible');
  const [myTimeout, setMyTimeout] = useState(null);

  const { gameId } = useParams();

  // clear timeout if navigating away
  useEffect(() => {
    return () => (myTimeout ? clearTimeout(myTimeout) : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animationState}
        transition={{ duration: 0.5 }}
        onAnimationComplete={(variant) => {
          if (variant === 'visible') {
            setMyTimeout(
              // after animating in, wait, then animate out
              setTimeout(function () {
                setAnimationState('hidden');
              }, 2000)
            );
          } else if (variant === 'hidden') {
            // after animating out, navigate to the next page
            navigate('/' + gameId + '/play');
          }
        }}
      >
        <Brand />
      </motion.div>
    </main>
  );
}
