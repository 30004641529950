import { motion } from 'framer-motion';

import { TextUnderage } from 'js/components';

export default function Page() {
  return (
    <main>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        <TextUnderage />
      </motion.div>
    </main>
  );
}
