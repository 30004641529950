import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import style from './Canvas.module.scss';

// options

const radius = 1500;

// generate gradients

const generateGradient = (ctx, endX, endY, fill) => {
  const grad = ctx.createLinearGradient(radius, radius, endX, endY);
  for (const i in fill) {
    grad.addColorStop(i / 100, fill[i]);
  }
  return grad;
};

// render component

export const Canvas = () => {
  const flavours = useSelector((models) => models.appModel.content.flavours);
  const canvasRef = useRef();

  const numSectors = flavours.length;
  const arcLength = (2 * Math.PI) / numSectors;
  const topPos = -0.5 * Math.PI;
  const startPos = topPos - arcLength / 2;
  const overlap = (2 * Math.PI) / 360 / 2;

  // initialise
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let startAngle = startPos;
    if (ctx) {
      for (const i in flavours) {
        const midAngle = startAngle + arcLength / 2;
        const endAngle = startAngle + arcLength;

        const endX = radius + radius * Math.cos(midAngle);
        const endY = radius + radius * Math.sin(midAngle);

        // if (i == 7) {
        // ctx.moveTo(radius, radius);
        ctx.beginPath();
        ctx.arc(radius, radius, radius, startAngle, endAngle + overlap);
        ctx.lineTo(radius, radius);
        ctx.closePath();
        ctx.fillStyle = generateGradient(ctx, endX, endY, flavours[i].fill);
        ctx.fill();
        // ctx.strokeStyle = '#000';
        // ctx.lineWidth = 5;
        // ctx.stroke();
        // }
        startAngle = endAngle;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <canvas ref={canvasRef} className={style.canvas} width={radius * 2} height={radius * 2}></canvas>;
};

export default Canvas;
