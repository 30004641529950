// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextLose.module.scss';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

export const TextLose = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const content = useSelector((models) => models.appModel.content.pages.lose);
  const gameid = useSelector((models) => models.appModel.gameId);
  const enable_replay_button = useSelector((models) => models.appModel.enable_replay_button);

  const flavours = useSelector((models) => models.appModel.content.flavours);
  const sectorResult = useSelector((models) => models.appModel.sectorResult);

  const theme = useSelector((models) => models.appModel.content.options.brand);

  const resultName = sectorResult ? flavours[sectorResult].name : 'XXXXXX';

  const handleReplay = () => {
    navigate('/' + gameid);
    dispatch.appModel.getGameData();
  };

  return (
    <div className={style.wrap}>
      <div className={clsx(style.title, { [style[theme]]: theme })}>{content.title}</div>
      <div className={style.subtitle}>{content.subtitle.replace('{{RESULT}}', resultName)}</div>
      <div className={style.body}>{content.body}</div>
      {enable_replay_button && (
        <button type="submit" onClick={() => handleReplay()} className={style.button}>
          {content.button_text}
        </button>
      )}
    </div>
  );
};

export default TextLose;
