import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { motion } from 'framer-motion';

import { TextPlay } from 'js/components';

export default function Page() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.appModel.playGame();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}> */}
      <TextPlay />
      {/* </motion.div> */}
    </main>
  );
}
