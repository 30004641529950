// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextWin.module.scss';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import clsx from 'clsx';

export const TextWin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const content = useSelector((models) => models.appModel.content.pages.win);
  const enable_replay_button = useSelector((models) => models.appModel.enable_replay_button);
  const gameid = useSelector((models) => models.appModel.gameId);
  const prizeCode = useSelector((models) => models.appModel.prizeCode);
  const prizeName = useSelector((models) => models.appModel.prizeName);
  const theme = useSelector((models) => models.appModel.content.options.brand);

  const handleReplay = () => {
    navigate('/' + gameid);
    dispatch.appModel.getGameData();
  };

  return (
    <div className={style.wrap}>
      <div className={clsx(style.title, { [style[theme]]: theme })}>{content.title}</div>
      <div className={style.prize}>{prizeName}</div>
      {content.code && <div className={style.codeTitle}>{content.code}</div>}
      {content.enable_qr && <QRCode value={content.code} fgColor="#fff" bgColor="transparent" />}
      {!content.enable_qr && !content.hide_code && <div className={style.code}>{prizeCode}</div>}
      {(content.panel_title || content.panel_body) && (
        <div className={style.panel}>
          <div className={style.panelTitle}>{content.panel_title}</div>
          <div className={style.panelBody}>{content.panel_body}</div>
        </div>
      )}
      {enable_replay_button && (
        <button type="submit" onClick={() => handleReplay()} className={style.button}>
          {content.button_text}
        </button>
      )}
    </div>
  );
};

export default TextWin;
