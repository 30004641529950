import { useEffect, useState } from 'react';

const getScreenSize = () => {
  let screenWidth = window.innerWidth;
  let screenHeight = window.innerHeight;
  if (window.navigator && !!window.navigator.standalone) {
    if (window.orientation && (window.orientation === 90 || window.orientation === -90)) {
      screenWidth = window.screen.height;
      screenHeight = window.screen.width;
    } else {
      screenWidth = window.screen.width;
      screenHeight = window.screen.height;
    }
  }
  return {
    screenWidth,
    screenHeight,
  };
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    ...getScreenSize(),
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        ...getScreenSize(),
      });
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
