import { useSelector } from 'react-redux';
import style from './Disclaimer.module.scss';

export const Disclaimer = () => {
  const content = useSelector((models) => models.appModel.content.disclaimer);

  return (
    <div className={style.wrap}>
      <div className={style.inner}>
        <div>{content.text}</div>
      </div>
    </div>
  );
};

export default Disclaimer;
