// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

export default function InvalidID() {
  // const isLocal = process.env.NODE_ENV === 'development';
  // const navigate = useNavigate();

  // // init
  // useEffect(() => {
  //   // local dev - populate placeholder game id
  //   if (isLocal) {
  //     const gameId = 'rsa_spin2win_game_test_1';
  //     navigate('/' + gameId);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <main>
      Sorry,
      <br />
      the game is not valid
    </main>
  );
}
