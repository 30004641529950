import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import store from 'js/store/store';
import style from './Select.module.scss';
import { useSelector } from 'react-redux';
import { getEndpoint } from 'js/utils';
import { useNavigate } from 'react-router-dom';

export const Select = () => {
  const gameid = useSelector((models) => models.appModel.gameId);

  const [tablets, setTablets] = useState([]);
  const navigate = useNavigate();
  const { api, host } = getEndpoint();

  const content = {
    title: 'Select your device',
    body: 'Please select one of the following options:',
    button_submit: 'Submit',
    validation_select: 'Please select an option',
    show_form: true,
  };

  const initialValue = {
    selectedOption: '',
  };

  const validationSchema = yup.object({
    selectedOption: yup.string().required(content.validation_select),
  });

  const onSubmit = (values) => {
    store.dispatch.appModel.setState({
      selectMode: false,
      inited: false,
    });
    store.dispatch.appModel.init({
      gameId: gameid,
      selectedUserId: values.selectedOption,
      navigate,
    });
    navigate(`/${gameid}`);
  };

  useEffect(() => {
    const getDevices = async () => {
      console.log('%c--- getGameData ---', 'color:#91074A');
      const endpoint = 'game-lookup/stores/' + gameid;
      const url = api + endpoint + (host === 'localhost' ? '.json' : '');
      axios
        .get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.status && res.data.status === 'ok') {
            setTablets(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameid]);

  const renderOptions = () => {
    return tablets.map((option) => (
      <option key={option.id} value={option.id}>
        {option.title}
      </option>
    ));
  };

  return (
    <div className={style.wrap}>
      <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting, isValid }) => (
          <>
            <div className={style.title}>
              <h1>{content.title}</h1>
            </div>
            <div className={style.body}>
              <p>{content.body}</p>
            </div>
            <Form className={style.form}>
              {content.show_form && (
                <>
                  <div className={style.fields}>
                    <Field as="select" name="selectedOption">
                      <option disabled value="">
                        Select a device
                      </option>
                      {renderOptions()}
                    </Field>
                  </div>

                  <div className={style.error}>
                    <ErrorMessage name="selectedOption" />
                  </div>

                  <button type="submit" className={style.button} disabled={!isValid || isSubmitting}>
                    {content.button_submit}
                  </button>
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Select;
