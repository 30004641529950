import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import axios from 'axios';

import * as yup from 'yup';
import store from 'js/store/store';
import clsx from 'clsx';
import style from './Modal.module.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getEndpoint } from 'js/utils';

const DeviceField = () => {
  const gameid = useSelector((models) => models.appModel.gameId);
  const { values } = useFormikContext();
  const [field] = useField('selectedOption');

  useEffect(() => {
    if (values.selectedOption) {
      store.dispatch.appModel.getEvents({ gameId: gameid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedOption]);

  return (
    <Field as="select" name="selectedOption" {...field}>
      <option disabled value="">
        Select a device
      </option>
      {values.tablets.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </Field>
  );
};

const Modal = ({ setShowModal }) => {
  const events = useSelector((models) => models.appModel.events);
  const gameid = useSelector((models) => models.appModel.gameId);
  const eventid = useSelector((models) => models.appModel.event.id);
  const selectedUserId = localStorage.getItem('selectedUserId');

  const dispatch = useDispatch();
  const [tablets, setTablets] = useState([]);
  const navigate = useNavigate();
  const { api, host } = getEndpoint();

  useEffect(() => {
    const getDevices = async () => {
      console.log('%c--- getGameData ---', 'color:#91074A');
      const endpoint = 'game-lookup/stores/' + gameid;
      const url = api + endpoint + (host === 'localhost' ? '.json' : '');
      axios
        .get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.status && res.data.status === 'ok') {
            setTablets(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameid]);

  const initialValues = {
    selectedEvent: eventid || '',
    selectedOption: selectedUserId || '',
    tablets: tablets,
  };

  const validationSchema = yup.object({
    selectedEvent: yup.string().required('Please select an event'),
    selectedOption: yup.string().required('Please select a device'),
  });

  const onSubmit = (values) => {
    const { selectedEvent, selectedOption } = values;

    if (initialValues.selectedEvent !== selectedEvent) {
      dispatch.appModel.setEvent({ id: selectedEvent, store_id: selectedOption });
    }

    if (initialValues.selectedOption !== selectedOption) {
      localStorage.setItem('selectedUserId', host === 'localhost' ? 'example' : selectedOption);
    }

    store.dispatch.appModel.setState({
      selectMode: false,
      inited: false,
    });
    store.dispatch.appModel.init({
      gameId: gameid,
      selectedUserId:
        initialValues.selectedOption !== selectedOption
          ? selectedOption
          : initialValues.selectedOption !== selectedOption,
      navigate,
    });

    setShowModal(false);
    navigate(`/${gameid}`);
  };

  const renderEventOptions = () => {
    return (
      events &&
      events.map((event) => (
        <option key={event.id} value={event.id}>
          {event.title} ({event.start_date} - {event.end_date})
        </option>
      ))
    );
  };

  return (
    <div className={style.Modal}>
      <div className={style.wrap}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, values }) => {
            const isChanged =
              initialValues.selectedEvent !== values.selectedEvent ||
              initialValues.selectedOption !== values.selectedOption;
            return (
              <>
                <div className={style.title}>
                  <h1>Select Your Device & Event</h1>
                </div>
                <div className={style.body}>
                  <p>Please select your device and event options:</p>
                </div>
                <Form className={style.form}>
                  <div className={style.fields}>
                    <Field as="select" name="selectedEvent">
                      <option disabled value="">
                        Select an event
                      </option>
                      {renderEventOptions()}
                    </Field>
                  </div>
                  <div className={style.fields}>
                    <DeviceField />
                  </div>

                  <div className={style.buttonCont}>
                    <button type="submit" className={style.button} disabled={!isValid || isSubmitting || !isChanged}>
                      Submit
                    </button>
                    <button
                      style={{ marginTop: '40px' }}
                      type="button"
                      className={clsx(style.button, style.outline)}
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Modal;
