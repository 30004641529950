import { motion } from 'framer-motion';

import { TextWin } from 'js/components';

export default function Page() {
  return (
    <main>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <TextWin />
      </motion.div>
    </main>
  );
}
