import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import store from 'js/store/store';
import { Select, Wrapper } from 'js/components';
import { AgeScreener, Brand, Error, Home, Lose, Play, Result, Underage, Win } from 'js/pages';
import NoPrizes from 'js/pages/NoPrizes';
import InvalidID from 'js/pages/InvalidID';

const App = () => {
  const isLocal = process.env.NODE_ENV === 'development';
  const navigate = useNavigate();
  const { pathname } = window.location;
  const pathSegments = pathname !== '/' ? pathname.split('/') : [];
  const gameId = pathSegments.length >= 1 ? pathSegments[1] : null;
  const store_id = pathSegments.length > 1 ? pathSegments[2] : null;
  const tracking_ref = pathSegments.length > 3 ? pathSegments[3] : null;

  // init
  useEffect(() => {
    let savedGameId = gameId;

    // for memory router, navigate using game id
    if (!isLocal && savedGameId) {
      navigate('/' + savedGameId);
    }

    // dev game id
    if (isLocal && !savedGameId) {
      savedGameId = 'rsa_spin2win_game_test_1';
      navigate('/' + savedGameId);
    }

    // fetch content and data etc
    store.dispatch.appModel.init({
      gameId: savedGameId,
      selectedUserId: store_id,
      tracking_ref: tracking_ref,
      navigate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Routes>
        <Route path="/" exact={true} element={<Error />} />
        <Route path="/:gameId" exact={true} element={<Home />} />
        <Route path="/:gameId/age-screener" exact={true} element={<AgeScreener />} />
        <Route path="/:gameId/underage" exact={true} element={<Underage />} />
        <Route path="/:gameId/brand" exact={true} element={<Brand />} />
        <Route path="/:gameId/play" exact={true} element={<Play />} />
        <Route path="/:gameId/result" exact={true} element={<Result />} />
        <Route path="/:gameId/lose" exact={true} element={<Lose />} />
        <Route path="/:gameId/win" exact={true} element={<Win />} />
        <Route path="/:gameId/select" exact={true} element={<Select />} />
        <Route path="/no-prizes" exact={true} element={<NoPrizes />} />
        <Route path="/invalid-id" exact={true} element={<InvalidID />} />
      </Routes>
    </Wrapper>
  );
};

export default App;
