import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclaimer, Legal, Logo, Rotate, Spinner, Modal } from 'js/components';
import style from './Wrapper.module.scss';

const Wrapper = ({ children }) => {
  const [clickCount, setClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const inited = useSelector((models) => models.appModel.inited);
  const content = useSelector((models) => models.appModel.content);
  const selectMode = useSelector((models) => models.appModel.selectMode);

  const enable_events_tripple_tap = useSelector((models) => models.appModel.enable_events_tripple_tap);

  const handleTripleClick = () => {
    if (clickCount === 2) {
      console.log('Triple click detected!');
      setShowModal(true);
    }
    setClickCount(clickCount + 1);
    setTimeout(() => {
      setClickCount(0);
    }, 500);
  };

  if (!content && !inited && !selectMode) {
    return null;
  }

  if (!content) {
    return (
      <>
        <div
          className={style.bg}
          style={{
            backgroundColor: '#141414',
          }}
        ></div>
        <div className={style.outer}>
          <div className={style.inner}>
            <div className={style.pages}>{children}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={style.bg}
        style={{
          backgroundColor: content.options.bg_color,
          backgroundImage: 'url(' + content.options.bg_image + ')',
        }}
      ></div>
      <div
        className={style.outer}
        style={{
          color: content.options.text_color,
        }}
      >
        {content.disclaimer.position === 'top' && <Disclaimer />}
        <div className={style.inner}>
          <Logo />
          <div className={style.pages}>{children}</div>
          <Spinner />
          <Legal />
          {showModal && <Modal setShowModal={setShowModal} />}
        </div>
        {content.disclaimer.position === 'bottom' && <Disclaimer />}
      </div>
      <Rotate />
      {enable_events_tripple_tap && (
        <div
          className={style.hotCorner}
          style={{ width: '10%', height: '10%', position: 'absolute', bottom: '0', left: '0' }}
          onClick={handleTripleClick}
        />
      )}
    </>
  );
};

export default Wrapper;
