import { useSelector } from 'react-redux';

import style from './Rotate.module.scss';

export const Rotate = () => {
  const content = useSelector((models) => models.appModel.content.options);

  return (
    <div
      className={style.wrap}
      style={{
        backgroundColor: content.bg_color,
        backgroundImage: 'url(' + content.bg_image + ')',
      }}
    >
      <div className={style.content}>{content.rotate}</div>
    </div>
  );
};

export default Rotate;
