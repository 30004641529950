import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Logo } from 'js/components';
import { useSelector } from 'react-redux';

export default function Page() {
  const navigate = useNavigate();
  const [animationState, setAnimationState] = useState('visible');
  const [myTimeout, setMyTimeout] = useState(null);
  const options = useSelector((models) => models.appModel.content?.options);
  const winner = useSelector((models) => models.appModel.alreadyWon);
  const selectMode = useSelector((models) => models.appModel.selectMode);

  const { gameId } = useParams();

  // clear timeout if navigating away
  useEffect(() => {
    return () => (myTimeout ? clearTimeout(myTimeout) : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!options && !selectMode) {
      navigate('/');
    }
  }, [navigate, options, selectMode]);

  if (options) {
    return (
      <main>
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animationState}
          transition={{ duration: 0.5 }}
          onAnimationComplete={(variant) => {
            if (variant === 'visible') {
              setMyTimeout(
                // after animating in, wait, then animate out
                setTimeout(function () {
                  setAnimationState('hidden');
                }, 2000)
              );
            } else if (variant === 'hidden') {
              // after animating out, navigate to the next page
              navigate('/' + gameId + (winner ? '/win' : options.ignore_age_screener ? '/brand' : '/age-screener'));
            }
          }}
        >
          <Logo variant="home" />
        </motion.div>
      </main>
    );
  }
}
