import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { AgeScreener } from 'js/components';

export default function Page() {
  const navigate = useNavigate();
  const [animationState, setAnimationState] = useState('visible');
  const [ageSuccess, setAgeSuccess] = useState('visible');

  const { gameId } = useParams();

  // after form submission, animate out
  const formCallback = (success) => {
    setAgeSuccess(success);
    setAnimationState('hidden');
  };

  return (
    <main>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animationState}
        transition={{ duration: 0.5 }}
        onAnimationComplete={(variant) => {
          // after animating out, navigate to the next page
          if (variant === 'hidden') {
            if (ageSuccess) {
              navigate('/' + gameId + '/brand');
            } else {
              navigate('/' + gameId + '/underage');
            }
          }
        }}
      >
        <AgeScreener formCallback={formCallback} />
      </motion.div>
    </main>
  );
}
