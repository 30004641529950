import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import style from './Logo.module.scss';

export const Logo = ({ variant = 'header' }) => {
  const { pathname } = useLocation();

  const content = useSelector((models) => models.appModel.content.options);
  const [isVisible, setIsVisible] = useState(pathname.split('/').length > 2 || variant !== 'header');

  useEffect(() => {
    setIsVisible(pathname.split('/').length > 2 || variant !== 'header');
  }, [pathname, variant]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={clsx(style.wrap, style[variant])}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial={variant === 'header' ? 'hidden' : 'visible'}
          animate="visible"
          transition={{ duration: 0.5 }}
        >
          <div className={clsx(style.logo, style[variant])}>
            <img src={content.logo} width={content.logo_width} height={content.logo_height} alt="Brand logo" />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Logo;
